<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="header-title">Keywords</h1>
        </div>
        <div class="col-auto">
          <router-link to="/create-keyword" class="btn btn-primary">Create Keyword</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese
// @group Keywords
// This Component is header that shows about the keyword list table
export default {
  name: 'KeywordListHeader',
}
</script>
