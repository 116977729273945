<template>
  <MainContent>
    <div class="container">
      <KeywordList />
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import KeywordList from '@/components/Modules/Keywords/KeywordList'
import { trackEvent } from '@/lib/analytics'

export default {
  name: 'KeywordsPage',
  components: {
    MainContent,
    KeywordList,
  },
  mounted() {
    trackEvent(this.$intercom, 'Keywords')
  },
}
</script>
