<template>
  <b-modal
    ref="modal"
    id="keywordDetailsModal"
    hide-footer
    hide-header
    centered
    body-class="p-0"
    size="lg"
  >
    <div class="modal-card card">
      <div class="card-header">
        <h2 class="m-0">Keyword Details</h2>
        <div class="text-right">
          <button class="btn btn-outline-primary btn-sm" @click="downloadCSV()">
            Download CSV
          </button>
        </div>
      </div>

      <!-- Table -->
      <div class="card-body" style="max-height: 650px">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <b-table
                :fields="fields"
                :items="locations"
                responsive
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :table-class="['card-table', 'table-nowrap']"
              >
                <template #head()="{ label, field: { key, sortable } }">
                  <span v-if="label == 'Magic Link'">
                    {{ label }}
                    <i
                      class="fe fe-info"
                      style="font-weight: bold; font-size: 12px"
                      v-b-popover.hover="popoverMessage"
                    ></i>
                  </span>
                  <span v-else class="mt-5">
                    {{ label }}
                  </span>
                  <template v-if="sortable">
                    <template v-if="sortBy !== key">
                      <i class="fe fe-arrow-down"></i>
                      <i class="fe fe-arrow-up"></i>
                    </template>
                    <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
                    <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
                  </template>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const keywordModule = createNamespacedHelpers('keyword')
const locationModule = createNamespacedHelpers('location')
const companyModule = createNamespacedHelpers('company')

export default {
  name: 'KeywordDetailsModal',
  components: {},
  data: () => ({
    keyword: undefined,
    popoverMessage:
      'When a customer clicks this link from their phone, it will auto-populate a text message with the seleted keyword to the particular phone number.',
    fields: [
      { key: 'location', sortable: true, class: ['align-middle'] },
      { key: 'phone', label: 'Phone Number', class: ['align-middle'] },
      { key: 'link', label: 'Magic Link', class: ['align-middle'] },
    ],
    sortDesc: false,
    sortBy: 'created',
    locations: [],
  }),
  mounted() {
    this.getUserLocationsByActiveCompany()
  },
  computed: {
    ...locationModule.mapGetters(['userActiveCompanyLocations']),
    ...companyModule.mapGetters({ activeCompany: 'activeCompany' }),
    phoneList() {
      let phones = []
      phones.push(this.activeCompany.phone)
      this.userActiveCompanyLocations?.forEach((location) => {
        if (location.bandwidth?.number) {
          phones.push(location.bandwidth.number)
        }
      })
      return phones
    },
    phonesWithoutLinks() {
      return this.phoneList.filter(
        (phone) => !this.keyword.magicLinks || !this.keyword.magicLinks[phone]
      )
    },
  },
  methods: {
    ...keywordModule.mapActions(['generateMagicLinks']),
    ...locationModule.mapActions(['getUserLocationsByActiveCompany']),
    async initialize(keyword) {
      this.keyword = keyword
      // check if there are any magic links, if not generate all
      if (keyword.magicLinks) {
        if (!this.verifyAllLinksExist()) {
          await this.getLinks(this.phonesWithoutLinks)
        }
      } else {
        await this.getLinks(this.phonesWithoutLinks)
      }
      this.populateLocations()
    },
    populateLocations() {
      // clear modal locations state and populate
      this.locations = []
      this.locations.push({
        location: 'Entire Company',
        phone: this.activeCompany.phone,
        link: this.keyword.magicLinks[this.activeCompany.phone],
      })
      const activeLocations = this.userActiveCompanyLocations.filter((l) => !l.disabled)

      activeLocations.forEach((location) => {
        this.locations.push({
          location: location.friendlyName ? location.friendlyName : location.city,
          phone: location.bandwidth?.number,
          link: this.keyword.magicLinks[location.bandwidth?.number],
        })
      })
    },
    verifyAllLinksExist() {
      let verified = true
      if (this.phonesWithoutLinks.length) {
        verified = false
      }
      return verified
    },
    async getLinks(phones) {
      let payload = {
        keywordId: this.keyword._id,
        fields: {
          phones,
          keyword: this.keyword.word,
        },
      }
      try {
        let response = await this.generateMagicLinks(payload)
        this.$notify({ text: 'Added missing link(s)' })
        this.keyword = response
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: (error.data && error.data.message) || 'Adding missing links failed!',
        })
      }
    },
    close() {
      this.$refs.modal.hide()
    },
    downloadCSV() {
      const csv = this.generateCSV()
      let fileURL = window.URL.createObjectURL(new Blob([csv]))
      let fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `keyword_${this.keyword.word}_details.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    generateCSV() {
      const header = ['NAME', 'PHONE', 'MAGIC LINK']
      let csvRows = []
      this.locations.forEach((loc) => {
        let locCols = []
        locCols.push(`"${loc.location || ''}"`)
        locCols.push(loc.phone || '')
        locCols.push(loc.link || '')
        csvRows.push(locCols.join(','))
      })
      return [header.join(','), ...csvRows].join('\r\n')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
</style>
