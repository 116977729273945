<template>
  <div class="dropdown d-inline-block">
    <a class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" href="#!" role="button">
      <i class="fe fe-more-vertical"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" v-if="keyword.company">
      <a
        v-for="item in optionsDropdownItems"
        :key="item.text"
        @click="item.action"
        class="dropdown-item"
        href="javascript:;"
      >
        <span :class="item.type">{{ item.text }}</span>
      </a>
    </div>
  </div>
</template>

<script>
// @group Keywords
/**
 * This component is the vertical ellipses at the right side of Keyword Table.
 * When clicked it shows additional actions.
 * */
export default {
  name: 'KeywordListDropdown',
  props: {
    // The selected Keyword from the table.
    keyword: {
      type: Object,
      required: true,
    },
  },
  computed: {
    optionsDropdownItems() {
      return [
        {
          type: 'text-primary',
          text: 'Edit',
          action: () => {
            // Notifies parent component to navigate to the edit page
            // @arg No parameters
            this.$emit('edit')
          },
        },
        {
          type: 'text-danger',
          text: 'Archive',
          action: () => {
            // Notifies parent component to archive the selected keyword
            // @arg No parameters
            this.$emit('archive')
          },
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss"></style>
