<template>
  <div class="card col-12">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/rocket.png"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 150px"
          />

          <!-- <div> -->
          <h2>Ready to automate your actions?</h2>

          <p class="text-muted mb-4">
            Create Keywords your customers can text to trigger actions you set.
          </p>
          <!-- </div> -->

          <router-link to="/create-keyword" class="btn btn-lg btn-primary">
            Create Keyword
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese
// @group Keywords
// This Component only displays if there are no existing keywords for a company.
export default {
  name: 'KeywordOffstate',
}
</script>

<style scoped lang="scss"></style>
